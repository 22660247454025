<template>
  <v-menu v-model="menu" close-on-click offset-y :disabled="readonly">
    <template v-if="$scopedSlots.activator" #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <template v-else #activator="{ on, attrs }">
      <v-btn
        elevation="0"
        class="text-capitalize"
        v-bind="attrs"
        text
        :small="isMobileOrIPad"
        v-on="on"
      >
        {{ $t('Actions') }}
        <v-icon class="ml-2" color="secondary" x-small>
          fas fa-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list v-if="menu" class="py-1">
      <v-list-item v-if="!isContractor && isDesignedVr" class="pa-0">
        <v-btn
          v-bind="btnDefaultProps"
          :disabled="
            Boolean(!listingTask.extra_data.current_guest_conversation_id)
          "
          color="info"
          @click="
            openChat({
              id: listingTask.extra_data.current_guest_conversation_id,
            })
          "
        >
          <v-icon small left>fas fa-comment</v-icon>
          {{ $t('Chat Guest') }}
        </v-btn>
      </v-list-item>
      <v-list-item v-if="isDesignedVr" class="pa-0">
        <shopping-list-modal :listing-task="listingTask" />
      </v-list-item>
      <v-list-item v-if="!isContractor && isDesignedVr" class="pa-0">
        <v-btn
          v-bind="btnDefaultProps"
          :href="`tel:+${phoneNumber}`"
          :disabled="!phoneNumber"
          color="info"
        >
          <v-icon small left>fas fa-phone</v-icon>

          {{ $t('Call Guest') }}
        </v-btn>
      </v-list-item>
      <v-list-item
        v-if="
          (isOperationsPersonal || isCommunicationPerson || isAdmin) &&
          listingTask
        "
        class="pa-0"
      >
        <LtContinuationModal
          :allowed-dates="allowedDates"
          button-text="Continuation"
          :listing-task-id="listingTask.id"
          @click.stop.prevent
        />
      </v-list-item>
      <v-list-item
        v-if="
          (isOperationsPersonal ||
            isCommunicationManager ||
            isAdmin ||
            cleaningRoles) &&
          listingTask &&
          listingTask.reg_cleaning
        "
        class="pa-0"
      >
        <LtContinuationModal
          button-text="Claim"
          :entry-mode="true"
          :allowed-dates="allowedDates"
          :pre-fields="claimLt"
          :listing-task-id="listingTask.id"
          @click.stop.prevent
        />
      </v-list-item>
      <v-list-item v-if="isDesignedVr" class="pa-0">
        <ConfirmationModal
          :text="$t('Are you sure you want to call cleaning?')"
          @action="callCleaning"
        >
          <template #activator="slotData">
            <v-btn
              v-bind="btnDefaultProps"
              color="info"
              :disabled="Boolean(listingTask.cleaning_task_id)"
              v-on="slotData.on"
            >
              <v-icon small left>fas fa-broom</v-icon>
              {{ $t('Call Cleaning') }}
            </v-btn>
          </template>
        </ConfirmationModal>
      </v-list-item>
      <v-list-item
        v-if="
          managementRoles &&
          !listingTask.blocked_listing_date &&
          listingTask.can_block &&
          isDesignedVr
        "
        class="pa-0"
      >
        <ConfirmationModal
          :text="`This would BLOCK ${parseDate(
            listingTask.scheduled_at
          )}, are you sure?`"
          @action="blockListing"
        >
          <template #activator="slotData">
            <v-btn
              v-bind="btnDefaultProps"
              color="orange darken-2"
              v-on="slotData.on"
            >
              <v-icon left>lock</v-icon>
              {{ $t('Block') }} ({{ parseDate(listingTask.scheduled_at) }})
            </v-btn>
          </template>
        </ConfirmationModal>
      </v-list-item>
      <v-list-item
        v-if="managementRoles && listingTask.blocked_listing_date"
        class="pa-0"
      >
        <ConfirmationModal
          :text="`This would UN-BLOCK ${parseDate(
            listingTask.scheduled_at
          )}, are you sure?`"
          @action="unBlockListing"
        >
          <template #activator="slotData">
            <v-btn
              v-bind="btnDefaultProps"
              color="green darken-2"
              v-on="slotData.on"
            >
              <v-icon left>lock_open</v-icon>
              {{ $t('Free') }} ({{ parseDate(listingTask.scheduled_at) }})
            </v-btn>
          </template>
        </ConfirmationModal>
      </v-list-item>
      <v-list-item
        v-if="
          managementRoles &&
          !listingTask.deposit &&
          !listingTask.manual_approved &&
          isDesignedVr
        "
        class="pa-0"
      >
        <LtDepositModal :task-id="listingTask.id" />
      </v-list-item>
      <v-list-item v-if="managementRoles && isDesignedVr" class="pa-0">
        <lt-followup-modal :listing-task="listingTask" />
      </v-list-item>
      <v-list-item
        v-if="managementRoles && listingTask.listing_id"
        class="pa-0"
      >
        <owner-approval-modal :listing-task="listingTask" />
      </v-list-item>
      <v-list-item
        v-if="
          managementRoles &&
          !listingTask.management_fee &&
          !listingTask.manual_approved
        "
        class="pa-0"
      >
        <LtManageFeeModal
          :amount="listingTask.amount"
          :task-id="listingTask.id"
        />
      </v-list-item>
      <v-list-item class="px-0">
        <warranty-modal :listing-task="listingTask" />
      </v-list-item>
      <v-list-item v-if="hasAbility('reviewer') && isDesignedVr" class="pa-0">
        <v-btn
          v-bind="btnDefaultProps"
          :disabled="listingTask.manager_review"
          @click="managerReview"
        >
          <v-icon small left>fas fa-certificate</v-icon>
          {{ $t('Manager review') }}
        </v-btn>
      </v-list-item>
      <v-list-item v-if="canDoListingTasks && isDesignedVr" class="pa-0">
        <requests :only-take="true">
          <template #customBtn="{ on }">
            <v-btn v-bind="btnDefaultProps" v-on="on">
              <v-icon small left>inventory</v-icon>
              {{ $t('Take from Inventory') }}</v-btn
            >
          </template>
        </requests>
      </v-list-item>
      <v-list-item
        v-if="
          listingTask.listing_task_template_id &&
          (managementRoles || isCleaningManager)
        "
        class="pa-0"
      >
        <ConfirmationModal
          text="Are you sure you want to continue this task flow ?"
          @action="continueListingTaskFlow"
        >
          <template #activator="slotData">
            <v-btn
              v-bind="btnDefaultProps"
              :rounded="false"
              :disabled="isTaskAlreadyDoneBefore"
              v-on="slotData.on"
            >
              <v-icon small left>account_tree</v-icon>
              {{ $t('Continue Flow') }}
            </v-btn>
          </template>
        </ConfirmationModal>
      </v-list-item>
      <v-list-item v-if="managementRoles || isCleaningManager" class="pa-0">
        <ConfirmationModal
          :text="deleteTaskConfirmationText"
          @action="deleteListingTask"
        >
          <template #activator="slotData">
            <v-btn v-bind="btnDefaultProps" color="warning" v-on="slotData.on">
              <v-icon small left>fas fa-trash</v-icon>

              {{ $t('Delete') }}
            </v-btn>
          </template>
        </ConfirmationModal>
      </v-list-item>
      <v-list-item class="pa-0">
        <v-btn
          v-bind="btnDefaultProps"
          color="yellow darken-2"
          @click="directionsTo(listingTask.listing.address)"
        >
          <v-icon small left>mdi-google-maps</v-icon>

          {{ $t('Navigate') }}
        </v-btn>
      </v-list-item>
      <v-list-item class="pa-0">
        <ConfirmationModal
          :text="`Are you sure you want to ${
            listingTask.is_outstanding ? 'unmark' : 'mark'
          } this task as Outstanding`"
          @action="toggleOutstanding"
        >
          <template #activator="slotData">
            <v-btn
              v-bind="btnDefaultProps"
              color="amber darken-4"
              v-on="slotData.on"
            >
              <v-icon color="amber darken-3" small left
                >mdi-alert-circle</v-icon
              >
              {{
                listingTask.is_outstanding
                  ? $t('Unmark as outstanding')
                  : $t('Mark as outstanding')
              }}
            </v-btn>
          </template>
        </ConfirmationModal>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import get from 'lodash/fp/get'
import LtContinuationModal from 'components/listing-tasks/lt-continuation-modal'
import LtDepositModal from 'components/listing-tasks/lt-deposit-modal'
import LtManageFeeModal from 'components/listing-tasks/lt-manage-fee-modal'
import WarrantyModal from 'components/warranty-modal'
import LtFollowupModal from 'components/listing-tasks/lt-followup-modal'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapActions, mapGetters } from 'vuex'
import ShoppingListModal from 'components/shopping-list/shopping-list-modal'
import LtMixin from 'components/mixins/lt-mixin'
import Requests from 'components/inventory/requests/requests.vue'
import NavigationMixin from 'components/mixins/navigation-mixin'
import OwnerApprovalModal from 'components/listing-tasks/owner-approval-modal.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'

const claimCatalogItemId = 330

export default {
  name: 'LtMenuActions',
  components: {
    OwnerApprovalModal,
    ShoppingListModal,
    WarrantyModal,
    LtFollowupModal,
    LtManageFeeModal,
    LtDepositModal,
    LtContinuationModal,
    ConfirmationModal,
    Requests,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    DeviceMixin,
    LtMixin,
    NavigationMixin,
    FormattersMixin,
  ],
  props: ['listingTask', 'readonly'],
  data() {
    return {
      menu: false,
      btnDefaultProps: {
        small: true,
        block: true,
        text: true,
        color: 'info',
        elevation: '0',
        class: 'rounded-0 justify-start',
      },
      claimLt: {
        listing_task_catalog_item_id: claimCatalogItemId,
        description: this.listingTask.claim_text,
        days_rates: [],
        house_check_out_dates: [],
        listing_task_catalog_item_name: 'Cleaning team claim',
      },
    }
  },
  computed: {
    ...mapGetters(['currentUserId']),
    phoneNumber() {
      return get('extra_data.effected_guest.phone_number', this.listingTask)
    },
    isTaskAlreadyDoneBefore() {
      if (!this.listingTask.log) return false
      for (const logKey in this.listingTask.log) {
        const log = this.listingTask.log[logKey]
        if (
          log.msg &&
          log.msg.startsWith('Created next task in auto template flow')
        ) {
          return true
        }
      }
      return false
    },
    deleteTaskConfirmationText() {
      if (this.listingTask.expense) {
        return `Are you sure you want to delete listing task?<br/>Related expense of ${this.toMoney(
          this.listingTask.expense.amount
        )} will be deleted permanently.`
      } else {
        return 'Are you sure you want to delete listing task?'
      }
    },
  },
  methods: {
    ...mapActions(['incrementQuickTasks']),
    ownerApproval() {
      this.$store.commit('showModal', {
        name: 'UpdateFieldsModal',
        props: {
          onConfirm: _fields => ({}),
          item: {
            address: this.listingTask.listing_name,
          },
          fields: [
            {
              select: true,
              text: 'Choose Owner',
              value: null,
              items: [{ text: 'yuval', value: 'zalka' }],
            },
          ],
        },
      })
    },
    managerReview() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        manager_review: true,
      })
    },
    toggleOutstanding() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        is_outstanding: !this.listingTask.is_outstanding,
      })
    },
    blockListing() {
      this.$store.dispatch('blockAction', {
        id: this.listingTask.id,
        block: true,
      })
    },
    unBlockListing() {
      this.$store.dispatch('blockAction', {
        id: this.listingTask.id,
        block: false,
      })
    },
    callCleaning() {
      this.$store.dispatch('createCleaningTask', this.listingTask.id)
    },
    allowedDates(val) {
      const parsedDate = this.$moment(val).utc().add(1, 'days')
      const timeNow = this.$moment().utc()
      return Boolean(
        timeNow <= parsedDate &&
          (!this.listingTask.house_vacant_dates[val] ||
            this.listingTask.house_check_in_dates[val] ||
            this.listingTask.house_check_out_dates[val])
      )
    },
    continueListingTaskFlow() {
      this.$store.dispatch('continueListingTaskFlow', this.listingTask.id)
    },
    deleteListingTask() {
      this.$store.dispatch('deleteListingTask', this.listingTask.id)
      if (this.currentRoute.includes('listing-task/')) {
        this.$router.push(`/dashboard/service-calls/area-report`)
      } else if (this.currentRoute.includes('/area-report')) {
        this.incrementQuickTasks()
      }
      this.$emit('listing-task-deleted')
    },
  },
}
</script>

<style scoped></style>
