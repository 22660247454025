<template>
  <v-container>
    <div class="text-h6">Notification Settings</div>
    <div class="text-caption font-weight-medium secondary--text">
      Select the kinds of notifications you would like to get about your
      activities
    </div>

    <v-divider class="my-4"></v-divider>

    <template v-if="user">
      <v-list dense max-width="500">
        <v-list-item
          v-for="(item, index) in filteredNotificationSettings"
          :key="index"
        >
          <v-list-item-content
            class="d-flex justify-space-between align-center"
          >
            <div class="d-flex justify-space-between align-center">
              <span class="text-subtitle-2 font-weight-medium"
                >{{ item.label }}
                <v-tooltip v-if="item.description" top>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on"> $info </v-icon>
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
              </span>

              <v-switch
                v-model="notifications[item.key]"
                color="info"
                @change="updateNotifications({ [item.key]: $event })"
              ></v-switch>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-4"></v-divider>
        <template v-if="isAdmin && user.role !== 'investor'">
          <div class="text-subtitle-1 font-weight-medium mb-2">
            Feed Channel Notifications
          </div>

          <v-text-field
            v-model="feedChannelSearch"
            label="Search feed channels"
            prepend-inner-icon="$search"
            dense
            outlined
            clearable
            class="mb-4"
            hide-details
          ></v-text-field>

          <v-list dense max-width="500">
            <v-list-item
              v-for="channel in filteredFeedChannels"
              :key="'feed_' + channel.id"
            >
              <v-list-item-content>
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="notifications['feed_channel_' + channel.id]"
                    color="info"
                    class="mt-0 pt-0"
                    hide-details
                    @change="
                      updateNotifications({
                        ['feed_channel_' + channel.id]: $event,
                      })
                    "
                  ></v-checkbox>
                  <span class="text-subtitle-2 font-weight-medium ml-2">
                    #{{ channel.name }}
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" small v-on="on">$info</v-icon>
                      </template>
                      <span
                        >Receive notifications for {{ channel.name }} feed</span
                      >
                    </v-tooltip>
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-list>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'UserNotifications',
  props: {
    user: {
      type: Object,
      required: true,
    },
    immediateUpdate: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      notifications: {},
      feedChannels: [],
      feedChannelSearch: '',
      notificationSettings: [
        {
          key: 'new_message',
          label: 'New Guest Message',
          roles: ['non-investor'],
        },
        {
          key: 'email_notification',
          label: 'Email Notifications',
          roles: [],
          description:
            "Get emails to find out what's going on when you're not online. You can turn this off",
        },
        {
          key: 'new_reservation',
          label: 'New Reservation',
          roles: ['investor'],
        },
        {
          key: 'canceled_reservation',
          label: 'Canceled Reservation',
          roles: ['investor'],
        },
        {
          key: 'cs_ai_escalation',
          label: 'Customer Support escalation',
          roles: ['non-investor'],
        },
        {
          key: 'sales_ai_escalation',
          label: 'Sales escalation',
          roles: ['non-investor'],
        },
      ],
    }
  },
  created() {
    this.fetchFeedChannels()
  },
  watch: {
    user: {
      handler(newUserValue) {
        this.notifications = newUserValue
          ? newUserValue.notifications || {}
          : this.currentUser.notifications || {}
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    filteredNotificationSettings() {
      return this.notificationSettings.filter(setting => {
        if (setting.roles.includes('all')) {
          return true
        }
        if (
          setting.roles.includes('non-investor') &&
          this.user.role !== 'investor'
        ) {
          return true
        }
        return setting.roles.includes(this.user.role)
      })
    },
    filteredFeedChannels() {
      if (!this.feedChannelSearch) return this.feedChannels

      const search = this.feedChannelSearch.toLowerCase()
      return this.feedChannels.filter(channel =>
        channel.name.toLowerCase().includes(search)
      )
    },
  },
  methods: {
    updateNotifications(options) {
      this.notifications = { ...this.notifications, ...options }

      if (this.immediateUpdate) {
        this.saveNotifications(options)
      } else {
        this.$emit('update:notifications', { ...this.notifications })
      }
    },
    saveNotifications(options = {}) {
      this.$store.dispatch('users/updateProfile', {
        id: this.user ? this.user.id : this.currentUser.id,
        notifications: { ...this.notifications, ...options },
      })
    },
    async fetchFeedChannels() {
      try {
        const response = await this.$store.dispatch('feeds/getFeedChannelNames')
        this.feedChannels = response.data.channels
      } catch (error) {
        console.error('Error fetching feed channels:', error)
      }
    },
  },
}
</script>

<style scoped></style>
