<template>
  <div class="multi-container">
    <div v-if="!fetched" class="center-overlay">
      <loader-animation-palms />
    </div>
    <v-tabs v-if="!isMobile" v-model="activeTab" class="mb-4">
      <v-tab>
        <v-icon small left>mdi-calendar-month</v-icon>
        Calendar
      </v-tab>
      <v-tab v-if="managementRoles">
        <v-icon small left>mdi-block-helper</v-icon>
        Blocks
      </v-tab>
    </v-tabs>
    <v-tabs-items v-if="!isMobile" v-model="activeTab">
      <v-tab-item>
        <div>
          <v-row
            v-if="managementRoles"
            class="align-center py-3 px-2 p-relative"
            style="z-index: 1"
          >
            <v-col cols="12" md="2">
              <v-text-field
                v-model="searchTerm"
                label="Filter by address"
                clearable
                outlined
                dense
                hide-details
                @click:clear="
                  searchTerm = ''
                  getData(1)
                "
                @keyup="onKeyUp"
              />
            </v-col>
            <v-col v-if="!isInvestor" class="select-col" cols="6" md="2">
              <v-select
                v-model="region"
                dense
                outlined
                hide-details
                label="Regions"
                clearable
                :items="listingRegions"
                @change="changeRegion"
              />
            </v-col>
            <v-col v-if="!isInvestor" class="select-col" cols="6" md="2">
              <v-dialog v-model="dialog" width="1200">
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey"
                    dense
                    outlined
                    hide-details
                    label="Regions"
                    clearable
                    append-icon="search"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left small class="pr-1">search</v-icon>
                    Search Listings
                  </v-btn>
                </template>
                <v-card class="pa-3">
                  <listings-search v-if="dialog" />
                </v-card>
              </v-dialog>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="listedOnly"
                hide-details
                class="mt-0"
                :label="$t('Listed Only')"
                @change="listedOnlyChange($event)"
              />
            </v-col>
            <v-col cols="12" class="ml-auto px-2" md="auto">
              <v-btn small primary="info" outlined @click="goToday"
                >today</v-btn
              >
              <date-picker
                style="z-index: 100"
                :icon="true"
                class="date-picker"
                label="Date"
                text-field-class="mx-2"
                @change="goToDate"
              />
              <v-btn icon @click="onDateNavigation(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="font-weight-medium text-subtitle-1 mx-0">{{
                calApi ? calApi.view.title : ''
              }}</span>
              <v-btn icon @click="onDateNavigation(1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-progress-linear v-if="fetching" color="primary" indeterminate />
          <full-calendar id="multi-cal" ref="calendar" :options="config">
            <template #slotLabelContent="{ date, text }">
              <div class="label-container">
                <div class="text-caption font-weight-medium black950--text">
                  {{ text }}
                </div>
                <div class="text-caption black950--text">
                  {{ parseDate(date, 'M.D', { local: true }) }}
                </div>
              </div>
            </template>
            <template #resourceLabelContent="{ resource }">
              <v-btn
                v-if="resource.id == 999999 && !searchTerm"
                color="info"
                small
                @click="loadMore"
                >Load more ...
              </v-btn>
              <span v-else class="cell">
                <span
                  v-if="
                    !resource.extendedProps.isMultiUnit &&
                    !resource.extendedProps.isHotel
                  "
                >
                  <v-icon
                    v-if="resource.extendedProps.isDirty"
                    color="error"
                    left
                    :size="isMobile ? 10 : 18"
                    >fas fa-broom</v-icon
                  >
                  <v-icon v-else left color="success" :size="isMobile ? 10 : 18"
                    >fas fa-broom</v-icon
                  >
                </span>

                <router-link
                  :to="
                    isInvestor
                      ? `/dashboard/property/${resource.id}`
                      : `/dashboard/edit/${resource.id}`
                  "
                  :target="isMobile ? '_self' : '_blank'"
                  class="text-decoration-none text-body-2 black950--text"
                  :class="{
                    'small-text': isMobile,
                    'font-weight-black': resource.extendedProps.isHotel,
                    'font-weight-semibold': resource.extendedProps.isMultiUnit,
                  }"
                >
                  <v-badge
                    v-if="!resource.extendedProps.listed"
                    left
                    dot
                    color="error"
                  >
                    {{ resource.title }}
                  </v-badge>
                  <span v-else>
                    {{ resource.title }}
                  </span>
                </router-link>
              </span>
              <v-chip
                v-if="resource.extendedProps.stage === 'long-term'"
                x-small
                color="black"
                label
                class="mx-2 white--text"
                >LTR</v-chip
              >
            </template>
            <template #slotLaneContent="{ isPast }">
              <v-overlay v-if="isPast" :opacity="0.1" absolute />
            </template>
            <template #resourceLaneContent="{ resource }">
              <v-sheet
                v-if="
                  !resource.extendedProps.isHotel &&
                  !resource.extendedProps.isMultiUnit
                "
                class="d-flex justify-space-between p-absolute"
                width="100%"
                height="100%"
              >
                <v-sheet
                  v-for="(item, ind) in dateRangeArr"
                  :key="ind"
                  width="3.5%"
                  height="100%"
                  class="text-end text-caption d-flex align-center justify-center p-relative"
                  @click="onEventClick"
                >
                  <div
                    v-if="
                      resource.extendedProps.rates &&
                      resource.extendedProps.rates[item] &&
                      resource.extendedProps.rates[item].status === 'available'
                    "
                    class="d-flex flex-column align-end"
                  >
                    <v-icon
                      v-if="resource.extendedProps.rates[item].manual_override"
                      class="manual-override"
                      color="error lighten-2"
                      x-small
                      >mdi-marker</v-icon
                    >
                    <span
                      v-if="
                        !resource._resource.parentId ||
                        resource.extendedProps.isSingle
                      "
                      class="pricing-field text-caption"
                    >
                      <template
                        v-if="
                          !isCleaner &&
                          !isContractor &&
                          !isCleaningManager &&
                          !isCleaningSupervisor &&
                          resource.extendedProps.rates?.[item]?.price !==
                            undefined
                        "
                      >
                        {{
                          resource.extendedProps.rates[item].price !== '' &&
                          !isNaN(
                            Number(resource.extendedProps.rates[item].price)
                          )
                            ? Number(
                                resource.extendedProps.rates[item].price
                              ).toFixed(0)
                            : ''
                        }}
                      </template>
                    </span>
                    <span
                      v-if="
                        resource.extendedProps.rates[item].minNights &&
                        !resource.extendedProps.isUnit
                      "
                      class="min-nights"
                    >
                      <v-icon size="8" class="mr-1">fas fa-moon</v-icon
                      >{{ resource.extendedProps.rates[item].minNights }}
                    </span>
                  </div>
                </v-sheet>
              </v-sheet>
              <v-sheet v-else-if="resource.id == 999999"> </v-sheet>
              <v-sheet
                v-else-if="resource.extendedProps.isHotel"
                class="d-flex justify-space-between p-absolute"
                width="100%"
                height="100%"
              >
                <v-sheet
                  v-for="(item, ind) in dateRangeArr"
                  :key="ind"
                  width="4.76%"
                  height="100%"
                  :light="false"
                  class="text-caption text-center light400 cell-center"
                  @click="onEventClick"
                >
                  <div v-if="isPastDay(item)"></div>
                  <div v-else class="font-weight-bold">
                    <span
                      ><span class="success--text">{{
                        getHotelAvailable(resource, item)
                      }}</span>
                      / {{ getHotelAll(resource) }}</span
                    >

                    <!--              <span>{{ calc(resource.id, item) }} </span>-->
                    <!--              /-->
                    <!--              <span>{{ calcUnitsNum(resource.id) }}</span>-->
                  </div>
                </v-sheet>
              </v-sheet>
              <v-sheet
                v-else-if="
                  resource.extendedProps.stage != 'long-term' &&
                  !resource.extendedProps.isHotel &&
                  resource.extendedProps.isMultiUnit
                "
                class="d-flex justify-space-between p-absolute"
                width="100%"
                height="100%"
              >
                <v-sheet
                  v-for="(item, ind) in dateRangeArr"
                  :key="ind"
                  width="4.76%"
                  height="100%"
                  :light="false"
                  class="text-caption text-center cell-center flex-column"
                  @click="onEventClick"
                >
                  <div
                    v-if="
                      resource.extendedProps.rates &&
                      resource.extendedProps.rates[item] &&
                      resource.extendedProps.rates[item].price !== undefined &&
                      !isCleaner &&
                      !isContractor &&
                      !isCleaningManager &&
                      !isCleaningSupervisor
                    "
                    class="pricing-field text-caption"
                  >
                    {{
                      resource.extendedProps.rates[item].price !== '' &&
                      !isNaN(Number(resource.extendedProps.rates[item].price))
                        ? Number(
                            resource.extendedProps.rates[item].price
                          ).toFixed(0)
                        : ''
                    }}
                  </div>
                  <div v-if="isPastDay(item)"></div>
                  <v-sheet
                    v-else
                    width="100%"
                    class="d-flex justify-space-around"
                  >
                    <div>
                      <span class="success--text">
                        {{
                          (resource.extendedProps.rates &&
                            resource.extendedProps.rates[item]
                              ?.available_units) ||
                          getMultiAvailable(resource, item)
                        }}
                      </span>
                      /
                      <span>{{ resource.extendedProps.totalUnits }}</span>
                    </div>
                    <span
                      v-if="
                        resource.extendedProps.rates &&
                        resource.extendedProps.rates[item]?.minNights
                      "
                      class="min-nights"
                    >
                      <v-icon size="8" class="mr-1">fas fa-moon</v-icon
                      >{{ resource.extendedProps.rates[item]?.minNights }}
                    </span>
                  </v-sheet>
                </v-sheet>
              </v-sheet>
            </template>
            <template #eventContent="{ event }">
              <div
                v-if="event.extendedProps.viewType === 'reservation'"
                class="event-reservation-content d-flex align-center overflow-hidden px-2"
              >
                <span
                  class="white rounded-xl source-icon"
                  :class="
                    event.extendedProps.hasBalance ? 'availability-block' : ''
                  "
                >
                  <v-icon
                    v-if="event.extendedProps.hasBalance"
                    style="color: indianred !important"
                    small
                    >$money</v-icon
                  >
                  <v-tooltip
                    v-if="event.extendedProps.isOtaBlock"
                    max-width="300"
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        class="ml-2 font-weight-medium ellipsis-1"
                        v-on="on"
                      >
                        <v-icon small color="inherit"> info </v-icon>
                        <v-icon small class="mx-1">fas fa-lock</v-icon>
                      </span>
                    </template>
                    <div>
                      <div
                        v-if="event.title"
                        class="font-weight-medium text-subtitle-2"
                      >
                        {{ event.title }}
                      </div>
                      <div class="text-caption">
                        OTA blocks appear on the booking calendar when specific
                        dates are blocked on external channels like Airbnb or
                        Booking.com. This can happen due to data migration or
                        manual adjustments made by users on our dashboard. Note
                        that these blocks reflect the status from the OTA and
                        can only be removed by creating a manual reservation on
                        our dashboard.
                      </div>
                    </div>
                  </v-tooltip>
                  <v-icon v-else-if="event.extendedProps.isBlocked" small
                    >fas fa-lock</v-icon
                  >
                  <v-icon v-else-if="event.extendedProps.source" small>{{
                    $vuetify.icons.values[
                      event.extendedProps.source
                        .toLowerCase()
                        .replace(/[. ]/g, '')
                    ]
                      ? `$${event.extendedProps.source
                          .toLowerCase()
                          .replace(/[. ]/g, '')}`
                      : '$manual'
                  }}</v-icon>
                </span>
                <span
                  :title="event.title"
                  class="ml-2 font-weight-medium ellipsis-1"
                  >{{ event.title }}</span
                >
              </div>
            </template>
          </full-calendar>
        </div>
      </v-tab-item>
      <v-tab-item>
        <listings-blocks />
      </v-tab-item>
    </v-tabs-items>
    <div v-else>
      <v-row class="align-center py-3 px-2 p-relative" style="z-index: 1">
        <v-col cols="12" md="2">
          <v-text-field
            v-model="searchTerm"
            label="Filter by address"
            clearable
            outlined
            dense
            hide-details
            @click:clear="
              searchTerm = ''
              getData(1)
            "
            @keyup="onKeyUp"
          />
        </v-col>
        <v-col v-if="!isInvestor" class="select-col" cols="6" md="2">
          <v-select
            v-model="region"
            dense
            outlined
            hide-details
            label="Regions"
            clearable
            :items="listingRegions"
            @change="changeRegion"
          />
        </v-col>
        <v-col v-if="!isInvestor" class="select-col" cols="6" md="2">
          <v-dialog v-model="dialog" width="1200">
            <template #activator="{ on, attrs }">
              <v-btn
                color="blue-grey"
                dense
                outlined
                hide-details
                label="Regions"
                clearable
                append-icon="search"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left small class="pr-1">search</v-icon>
                Search Listings
              </v-btn>
            </template>
            <v-card class="pa-3">
              <listings-search v-if="dialog" />
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="listedOnly"
            hide-details
            class="mt-0"
            :label="$t('Listed Only')"
            @change="listedOnlyChange($event)"
          />
        </v-col>
        <v-col cols="12" class="ml-auto px-2" md="auto">
          <v-btn small primary="info" outlined @click="goToday">today</v-btn>
          <date-picker
            style="z-index: 100"
            :icon="true"
            class="date-picker"
            label="Date"
            text-field-class="mx-2"
            @change="goToDate"
          />
          <v-btn icon @click="onDateNavigation(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="font-weight-medium text-subtitle-1 mx-0">{{
            calApi ? calApi.view.title : ''
          }}</span>
          <v-btn icon @click="onDateNavigation(1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear v-if="fetching" color="primary" indeterminate />
      <full-calendar id="multi-cal" ref="calendar" :options="config">
        <template #slotLabelContent="{ date, text }">
          <div class="label-container">
            <div class="text-caption font-weight-medium black950--text">
              {{ text }}
            </div>
            <div class="text-caption black950--text">
              {{ parseDate(date, 'M.D', { local: true }) }}
            </div>
          </div>
        </template>
        <template #resourceLabelContent="{ resource }">
          <v-btn
            v-if="resource.id == 999999 && !searchTerm"
            color="info"
            small
            @click="loadMore"
            >Load more ...
          </v-btn>
          <span v-else class="cell">
            <span
              v-if="
                !resource.extendedProps.isMultiUnit &&
                !resource.extendedProps.isHotel
              "
            >
              <v-icon
                v-if="resource.extendedProps.isDirty"
                color="error"
                left
                :size="isMobile ? 10 : 18"
                >fas fa-broom</v-icon
              >
              <v-icon v-else left color="success" :size="isMobile ? 10 : 18"
                >fas fa-broom</v-icon
              >
            </span>

            <router-link
              :to="
                isInvestor
                  ? `/dashboard/property/${resource.id}`
                  : `/dashboard/edit/${resource.id}`
              "
              :target="isMobile ? '_self' : '_blank'"
              class="text-decoration-none text-body-2 black950--text"
              :class="{
                'small-text': isMobile,
                'font-weight-black': resource.extendedProps.isHotel,
                'font-weight-semibold': resource.extendedProps.isMultiUnit,
              }"
            >
              <v-badge
                v-if="!resource.extendedProps.listed"
                left
                dot
                color="error"
              >
                {{ resource.title }}
              </v-badge>
              <span v-else>
                {{ resource.title }}
              </span>
            </router-link>
          </span>
          <v-chip
            v-if="resource.extendedProps.stage === 'long-term'"
            x-small
            color="black"
            label
            class="mx-2 white--text"
            >LTR</v-chip
          >
        </template>
        <template #slotLaneContent="{ isPast }">
          <v-overlay v-if="isPast" :opacity="0.1" absolute />
        </template>
        <template #resourceLaneContent="{ resource }">
          <v-sheet
            v-if="
              !resource.extendedProps.isHotel &&
              !resource.extendedProps.isMultiUnit
            "
            class="d-flex justify-space-between p-absolute"
            width="100%"
            height="100%"
          >
            <v-sheet
              v-for="(item, ind) in dateRangeArr"
              :key="ind"
              width="3.5%"
              height="100%"
              class="text-end text-caption d-flex align-center justify-center p-relative"
              @click="onEventClick"
            >
              <div
                v-if="
                  resource.extendedProps.rates &&
                  resource.extendedProps.rates[item] &&
                  resource.extendedProps.rates[item].status === 'available'
                "
                class="d-flex flex-column align-end"
              >
                <v-icon
                  v-if="resource.extendedProps.rates[item].manual_override"
                  class="manual-override"
                  color="error lighten-2"
                  x-small
                  >mdi-marker</v-icon
                >
                <span
                  v-if="
                    !resource._resource.parentId ||
                    resource.extendedProps.isSingle
                  "
                  class="pricing-field text-caption"
                >
                  <template
                    v-if="
                      !isCleaner &&
                      !isContractor &&
                      !isCleaningManager &&
                      !isCleaningSupervisor &&
                      resource.extendedProps.rates?.[item]?.price !== undefined
                    "
                  >
                    {{
                      resource.extendedProps.rates[item].price !== '' &&
                      !isNaN(Number(resource.extendedProps.rates[item].price))
                        ? Number(
                            resource.extendedProps.rates[item].price
                          ).toFixed(0)
                        : ''
                    }}
                  </template>
                </span>
                <span
                  v-if="
                    resource.extendedProps.rates[item].minNights &&
                    !resource.extendedProps.isUnit
                  "
                  class="min-nights"
                >
                  <v-icon size="8" class="mr-1">fas fa-moon</v-icon
                  >{{ resource.extendedProps.rates[item].minNights }}
                </span>
              </div>
            </v-sheet>
          </v-sheet>
          <v-sheet v-else-if="resource.id == 999999"> </v-sheet>
          <v-sheet
            v-else-if="resource.extendedProps.isHotel"
            class="d-flex justify-space-between p-absolute"
            width="100%"
            height="100%"
          >
            <v-sheet
              v-for="(item, ind) in dateRangeArr"
              :key="ind"
              width="4.76%"
              height="100%"
              :light="false"
              class="text-caption text-center light400 cell-center"
              @click="onEventClick"
            >
              <div v-if="isPastDay(item)"></div>
              <div v-else class="font-weight-bold">
                <span
                  ><span class="success--text">{{
                    getHotelAvailable(resource, item)
                  }}</span>
                  / {{ getHotelAll(resource) }}</span
                >

                <!--              <span>{{ calc(resource.id, item) }} </span>-->
                <!--              /-->
                <!--              <span>{{ calcUnitsNum(resource.id) }}</span>-->
              </div>
            </v-sheet>
          </v-sheet>
          <v-sheet
            v-else-if="
              resource.extendedProps.stage != 'long-term' &&
              !resource.extendedProps.isHotel &&
              resource.extendedProps.isMultiUnit
            "
            class="d-flex justify-space-between p-absolute"
            width="100%"
            height="100%"
          >
            <v-sheet
              v-for="(item, ind) in dateRangeArr"
              :key="ind"
              width="4.76%"
              height="100%"
              :light="false"
              class="text-caption text-center cell-center flex-column"
              @click="onEventClick"
            >
              <div
                v-if="
                  resource.extendedProps.rates &&
                  resource.extendedProps.rates[item] &&
                  resource.extendedProps.rates[item].price !== undefined &&
                  !isCleaner &&
                  !isContractor &&
                  !isCleaningManager &&
                  !isCleaningSupervisor
                "
                class="pricing-field text-caption"
              >
                {{
                  resource.extendedProps.rates[item].price !== '' &&
                  !isNaN(Number(resource.extendedProps.rates[item].price))
                    ? Number(resource.extendedProps.rates[item].price).toFixed(
                        0
                      )
                    : ''
                }}
              </div>
              <div v-if="isPastDay(item)"></div>
              <v-sheet v-else width="100%" class="d-flex justify-space-around">
                <div>
                  <span class="success--text">
                    {{
                      (resource.extendedProps.rates &&
                        resource.extendedProps.rates[item]?.available_units) ||
                      getMultiAvailable(resource, item)
                    }}
                  </span>
                  /
                  <span>{{ resource.extendedProps.totalUnits }}</span>
                </div>
                <span
                  v-if="
                    resource.extendedProps.rates &&
                    resource.extendedProps.rates[item]?.minNights
                  "
                  class="min-nights"
                >
                  <v-icon size="8" class="mr-1">fas fa-moon</v-icon
                  >{{ resource.extendedProps.rates[item]?.minNights }}
                </span>
              </v-sheet>
            </v-sheet>
          </v-sheet>
        </template>
        <template #eventContent="{ event }">
          <div
            v-if="event.extendedProps.viewType === 'reservation'"
            class="event-reservation-content d-flex align-center overflow-hidden px-2"
          >
            <span
              class="white rounded-xl source-icon"
              :class="
                event.extendedProps.hasBalance ? 'availability-block' : ''
              "
            >
              <v-icon
                v-if="event.extendedProps.hasBalance"
                style="color: indianred !important"
                small
                >$money</v-icon
              >
              <v-tooltip
                v-if="event.extendedProps.isOtaBlock"
                max-width="300"
                top
              >
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="ml-2 font-weight-medium ellipsis-1"
                    v-on="on"
                  >
                    <v-icon small color="inherit"> info </v-icon>
                    <v-icon small class="mx-1">fas fa-lock</v-icon>
                  </span>
                </template>
                <div>
                  <div
                    v-if="event.title"
                    class="font-weight-medium text-subtitle-2"
                  >
                    {{ event.title }}
                  </div>
                  <div class="text-caption">
                    OTA blocks appear on the booking calendar when specific
                    dates are blocked on external channels like Airbnb or
                    Booking.com. This can happen due to data migration or manual
                    adjustments made by users on our dashboard. Note that these
                    blocks reflect the status from the OTA and can only be
                    removed by creating a manual reservation on our dashboard.
                  </div>
                </div>
              </v-tooltip>
              <v-icon v-else-if="event.extendedProps.isBlocked" small
                >fas fa-lock</v-icon
              >
              <v-icon v-else-if="event.extendedProps.source" small>{{
                $vuetify.icons.values[
                  event.extendedProps.source.toLowerCase().replace(/[. ]/g, '')
                ]
                  ? `$${event.extendedProps.source
                      .toLowerCase()
                      .replace(/[. ]/g, '')}`
                  : '$manual'
              }}</v-icon>
            </span>
            <span
              :title="event.title"
              class="ml-2 font-weight-medium ellipsis-1"
              >{{ event.title }}</span
            >
          </div>
        </template>
      </full-calendar>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      width="500"
      right
      app
      overlay-color="transparent"
      fixed
      touchless
      style="top: 0; z-index: 200"
      temporary
    >
      <div v-if="internalReservation">
        <v-card min-width="360" elevation="0">
          <conversation-info-title
            title="Reservation"
            :guesty-id="internalReservation.reservation_guesty_id"
          >
            <template #actions>
              <v-btn
                v-if="internalReservation.reservation_guesty_id"
                :to="`/dashboard/reservation/${internalReservation.reservation_guesty_id}`"
                target="_blank"
                icon
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
              <v-btn
                v-if="internalReservation.cleaning_listing_task_id"
                :to="{
                  name: 'lt-page',
                  params: { id: internalReservation.cleaning_listing_task_id },
                }"
                target="_blank"
                icon
              >
                <v-icon>mdi-broom</v-icon>
              </v-btn>
              <reservation-edit-modal
                :key="internalReservation.id"
                :reservation="internalReservation"
                :listing="resource.extendedProps"
                @change="onReservationEdit"
              />
            </template>
          </conversation-info-title>
          <v-divider />
          <v-card-text>
            <div
              v-if="internalReservation.guest"
              class="d-flex align-center mb-4"
            >
              <user-avatar
                :name="internalReservation.guest.full_name"
                :size="50"
              />
              <div class="ml-4">
                <p class="text-body-1 black--text mb-0">
                  {{ internalReservation.guest.full_name }}
                </p>
                <v-btn
                  class="pa-0"
                  :href="`tel:${internalReservation.guest.phones[0]}`"
                  text
                  x-small
                >
                  <v-icon class="mr-1" x-small>mdi-phone</v-icon>
                  {{ internalReservation.guest.phones[0] }}
                </v-btn>
              </div>
            </div>
            <p v-if="internalReservation.guest?.primary_email">
              <v-icon>person</v-icon>
              <span class="cyan-icon">Registered email: </span>
              {{ internalReservation.guest.primary_email }}
            </p>
            <v-row wrap no-gutters class="my-2">
              <v-col
                v-if="
                  isAdmin ||
                  isCommunicationManager ||
                  isCommunicationAgent ||
                  isSalesAgent ||
                  isSalesManager
                "
                cols="12"
              >
                <check-in-out-form
                  :reservation="internalReservation"
                  @change="updateCheckInOutTimes"
                />
              </v-col>
            </v-row>
            <v-row class="justify-space-between align-center">
              <v-col>
                {{ parseDate(internalReservation.check_in) }}
              </v-col>
              <v-col>
                <v-icon>arrow_right_alt</v-icon>
              </v-col>
              <v-col>
                {{ parseDate(internalReservation.check_out) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="text-h6">
                  <span
                    v-if="region"
                    class="text-capitalize font-weight-medium secondary--text"
                    >{{ region.name }} -
                  </span>
                  <span
                    >{{ resource.title }}
                    <copy-clipboard :text="resource.title"
                  /></span>
                </div>
              </v-col>
              <v-col offset="2" cols="8">
                <v-img contain :src="resource.extendedProps.picture" />
              </v-col>
            </v-row>
            <v-layout wrap align-center mt-1 mb-1 class="center-text bolder">
              <v-flex class="font-light br-1" xs3>
                <h3 class="grey-text">Price</h3>
                <span class="bolder light-purple">{{
                  toMoney(calcReservationPrice(internalReservation))
                }}</span>
              </v-flex>
              <v-flex class="font-light br-1" xs3>
                <h3 class="grey-text">Guests</h3>
                <span class="bolder light-purple">{{
                  internalReservation.guests_count
                }}</span>
              </v-flex>
              <v-flex class="font-light br-1" xs3>
                <h3 class="grey-text">Nights</h3>
                <span class="bolder light-purple">{{
                  internalReservation.nights_count
                }}</span>
              </v-flex>
              <v-flex class="font-light" xs3>
                <h3 class="grey-text">Channel</h3>
                <span class="bolder">{{ internalReservation.source }}</span>
              </v-flex>
            </v-layout>
            <v-row no-gutters class="py-2">
              <v-col cols="auto" class="pa-1">
                <listing-task-module
                  v-if="!loading && !listingLoading"
                  :pre-filled-listing-id="[internalReservation.listing_id]"
                  class="mt-2 mb-2"
                  button-text="SC"
                  :reservation="internalReservation"
                />
              </v-col>
              <v-col
                v-if="
                  internalReservation.status === 'confirmed' && isDesignedVr
                "
                cols="auto"
                class="pa-1"
              >
                <v-btn depressed color="primary" @click="sendInfo">
                  <v-icon pr-1>lock</v-icon>
                  Send Codes
                </v-btn>
              </v-col>
            </v-row>
            <v-flex
              v-if="
                isAdmin &&
                internalReservation.balance_due > 0 &&
                internalReservation.is_merchant
              "
            >
              <v-btn
                class="greenish purple-icon"
                @click="markAsPaid(internalReservation.id)"
                >Mark as paid
              </v-btn>
            </v-flex>
          </v-card-text>
        </v-card>
      </div>

      <conversation-info
        v-else-if="conversation"
        :key="conversation.id"
        :absolute="true"
        :fetching="fetchingChatConversation"
        :conversation="conversation"
        :single-reservation="true"
        :keep-expanded="true"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import NavigationMixin from 'components/mixins/navigation-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ConversationInfo from 'components/conversation-info'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { groupBy, keyBy, isEmpty, uniq, get, uniqBy, sum } from 'lodash/fp'
import axios from 'axios'
import LoaderAnimationPalms from 'components/loaders/loader-animation-palms'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/fp/isEqual'
import DatePicker from 'components/form-fields/date-picker'
import DeviceMixin from 'components/mixins/device-mixin'
import ConversationInfoTitle from 'components/chat/conversation-info-title.vue'
import UserAvatar from 'components/common/user-avatar.vue'
import CheckInOutForm from 'components/common/check-in-out-form.vue'
import CopyClipboard from 'components/copy-clipboard.vue'
import ListingTaskModule from 'components/listing-task-module.vue'
import ListingsSearch from 'components/listings-search.vue'
import ReservationEditModal from 'components/reservation/reservation-edit-modal'
import ListingsBlocks from '../crm/listings-blocks.vue'

export default {
  name: 'MultiCalendar',
  components: {
    ListingsSearch,
    ListingTaskModule,
    CopyClipboard,
    CheckInOutForm,
    UserAvatar,
    ConversationInfoTitle,
    DatePicker,
    LoaderAnimationPalms,
    FullCalendar,
    ConversationInfo,
    ReservationEditModal,
    ListingsBlocks,
  },
  props: ['listingId', 'disabled'],
  mixins: [
    CommonFunctions,
    FormattersMixin,
    PermissionsMixin,
    DeviceMixin,
    NavigationMixin,
  ],
  data() {
    return {
      currentDate: this.parseDate(new Date()),
      searchTerm: '',
      dates: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      },
      dialog: false,
      internalReservation: null,
      resource: null,
      listingsSet: new Set(),
      event: false,
      page: 1,
      from: null,
      to: null,
      region: null,
      resort: null,
      activeClass: ['darken-1'],
      statusClasses: {
        reserved: 'reserved',
        confirmed: 'confirmed',
        canceled: 'canceled',
      },
      eventClass: ['reservation-bar', 'ellipsis-1', 'event-reservation'],
      reservations: [],
      listings: [],
      multiUnitListings: [],
      totalListings: [],
      fetched: false,
      fetching: false,
      calScroll: null,
      debouncer: null,
      calApi: null,
      drawer: false,
      searchDialog: null,
      listedOnly: true,
      activeTab: 0,
    }
  },

  computed: {
    ...mapState('listingCalendar', ['calendarUpdates', 'calendarLoading']),
    ...mapState(['chosenChatReservation', 'fetchingChatConversation']),
    ...mapGetters(['currentUser', 'weekStartsAt']),
    ...mapState('regions', ['regions']),
    ...mapState(['app']),
    showCalendarHotels() {
      return this.app?.configuration?.ui_settings?.show_calendar_hotels
    },
    listingRegions() {
      return this.regions.map(r => ({ text: r.name, value: r.id }))
    },
    timezone() {
      return get('0.timezone', this.totalListings) || 'local'
    },
    resortsZone() {
      return !!this.currentUser.zone && this.currentUser.zone.name === 'Resorts'
    },
    resources() {
      const multiCalGroup = l => {
        if (!this.showCalendarHotels) return null
        return l.multi_nickname && !l.hotel_id
          ? l.multi_nickname
          : l.hotel_nickname
      }

      const grouped = groupBy(
        this.listingId ? 'multi_nickname' : multiCalGroup,
        this.listings
      )
      const sortTieBreak = 'ZZZZZ'
      const res = Object.entries(grouped).reduce((res, [id, listings]) => {
        if (id === sortTieBreak) {
          res = res.concat(
            listings.map((l, i) => ({
              index: i,
              id: l.id,
              title: l.nickname,
              title2: sortTieBreak,
              nickname: l.nickname,
              picture: l.picture,
              isDirty: l.is_dirty,
              rates: l.days_rates,
              timezone: l.timezone,
              stage: l.stage,
              listed: l.listed,
            }))
          )
        } else {
          const hotel_nick = listings[0].hotel_nickname
          const groupedMulti = groupBy('multi_nickname', listings)
          const multiKeys = keyBy('id', this.multiUnitListings)
          const multiChildren = Object.entries(groupedMulti).reduce(
            (res, [id, listingsUnits]) => {
              if (id === 'undefined') {
                listingsUnits.forEach(l => {
                  res.push({
                    index: l.index,
                    id: l.id,
                    isDirty: l.is_dirty,
                    title: l.nickname,
                    title2: l.nickname,
                    nickname: l.nickname,
                    isSingle: true,
                    picture: l.picture,
                    rates: l.days_rates,
                    timezone: l.timezone,
                    stage: l.stage,
                    listed: l.listed,
                  })
                })
              } else {
                res.push({
                  id: listingsUnits[0].container_multi_id,
                  title: listingsUnits[0].multi_nickname,
                  title2: listingsUnits[0].multi_nickname,
                  listed: multiKeys[listingsUnits[0].container_multi_id].listed,
                  isMultiUnit: true,
                  totalUnits: listingsUnits.length,
                  stage: 'regular',
                  rates:
                    multiKeys[listingsUnits[0].container_multi_id].days_rates,
                  children: listingsUnits.map((l, i) => ({
                    index: i,
                    id: l.id,
                    isUnit: true,
                    picture: l.picture,
                    title: l.nickname,
                    isDirty: l.is_dirty,
                    classNames: ['yuval'],
                    nickname: l.nickname,
                    rates: l.days_rates,
                    timezone: l.timezone,
                    stage: l.stage,
                    listed: l.listed,
                  })),
                })
              }
              return res
            },
            []
          )

          listings[0].hotel_id && this.showCalendarHotels
            ? res.push({
                id: listings[0].hotel_id,
                title: hotel_nick,
                isHotel: true,
                listed: listings[0].listed,
                title2: hotel_nick,
                stage: listings[0].stage,
                children: multiChildren,
              })
            : res.push(...multiChildren)
        }
        return res
      }, [])
      if (
        this.totalListings.length !== this.listings.length &&
        !this.searchTerm
      ) {
        res.push({
          id: 999999,
          title2: sortTieBreak,
          title: sortTieBreak,
        })
      }
      return res
    },
    events() {
      const reservation = this.reservations.reduce((acc, r) => {
        if (this.listingsSet.has(r.listing_id)) {
          const fullName = r.guest ? r.guest.full_name : ''
          const childResClass = r.multi_reservation_id ? 'child-res' : ''
          acc.push({
            title:
              r.source === 'Owner'
                ? 'Owner Vacation'
                : fullName + (r.status === 'canceled' ? ' (c)' : ''),
            resourceId: r.listing_id,
            source: r.source,
            viewType: 'reservation',
            hasBalance: r.balance_due > 0 && r.is_merchant,
            isBlocked: false,
            textColor: 'black',
            start: this.parseDateTZ(r.check_in, 'YYYY-MM-DD'),
            end: this.parseDateTZ(r.check_out, 'YYYY-MM-DD'),
            classNames: this.eventClass
              .concat(this.statusClasses[r.status])
              .concat(childResClass),
            id: r.id,
          })
        }
        return acc
      }, [])
      if (this.event) {
        const currentResEvent = reservation.find(r => r.id === +this.event.id)
        if (currentResEvent) {
          currentResEvent.textColor = 'white'
          currentResEvent.classNames = this.eventClass.concat(this.activeClass)
        }
      }
      const dates = this.dateRangeArr
      const events = []
      this.listings.map(l => {
        if (isEmpty(l.days_rates)) {
          return false
        }
        let ind = 0
        while (ind < dates.length) {
          const d = dates[ind]
          const currentRate = l.days_rates[d]
          if (!currentRate) {
            ind++
            continue
          }
          const isSmartRules =
            currentRate.note && currentRate.note.includes('Smart Rules')
          if (!isSmartRules && currentRate.status === 'unavailable') {
            const startDay = d
            let currentDay = d
            while (
              l.days_rates[currentDay] &&
              l.days_rates[currentDay].status === 'unavailable' &&
              l.days_rates[startDay].note === l.days_rates[currentDay].note &&
              l.days_rates[currentDay]['block_type'] ===
                l.days_rates[startDay]['block_type']
            ) {
              ind++
              currentDay = dates[ind]
            }
            const isOverlap = this.reservations.some(
              res =>
                res.listing_id === l.id &&
                this.$moment(res.check_in)
                  .startOf('day')
                  .isSameOrBefore(startDay) &&
                this.$moment(res.check_out).isSameOrAfter(
                  dates[ind] || dates[ind - 1]
                )
            )
            if (isOverlap) {
              continue
            }
            const otaBlock =
              l.days_rates[dates[ind - 1]].block_type &&
              l.days_rates[dates[ind - 1]].block_type === 'ota_auto_block'
            const smartBlock =
              l.days_rates[dates[ind - 1]].block_type &&
              l.days_rates[dates[ind - 1]].block_type === 'smart_rules'

            const blockClass =
              otaBlock || smartBlock ? 'availability-block' : 'block-bg'
            events.push({
              title: l.days_rates[dates[ind - 1]].note || 'N/A',
              resourceId: l.id,
              listingId: l.id,
              viewType: 'reservation',
              isBlocked: true,
              isOtaBlock: otaBlock,
              start: startDay,
              end: dates[ind] || dates[ind - 1],
              classNames: [blockClass, 'ellipsis-1', 'event-reservation'],
              id: Math.random(),
            })
            continue
          }
          ind++
        }
      })
      return uniqBy(
        item => `${item.id}${item.resourceId}`,
        reservation.concat(events).concat({
          title: '',
          resourceId: 999999,
          classNames: ['block-bg', 'ellipsis-1', 'event-reservation'],
          id: Math.random(),
        })
      )
    },
    conversation() {
      return this.$store.getters.currentConversation
    },
    dateRangeArr() {
      return this.generateDateRangeArray(this.from, this.to)
    },
    formattedEvents() {
      return this.events.map(event => this.formatCalendarEvent(event))
    },
    config() {
      return {
        height: 'auto',
        firstDay: this.weekStartsAt,
        plugins: [resourceTimelinePlugin, interactionPlugin],
        aspectRatio: 1,
        schedulerLicenseKey: '0765990167-fcs-1637703520',
        initialView: 'resourceTimelineMonth',
        resources: this.resources,
        selectable:
          !this.isInvestor &&
          !this.isCleaner &&
          !this.isCleaningManager &&
          !this.disabled,
        resourceOrder: 'title2,title',
        events: this.formattedEvents,
        select: data => {
          if (this.isInvestor || this.isCleaner || this.isCleaningManager) {
            return
          }
          this.$store.commit('showModal', {
            name: 'CalendarBlockModal',
            props: {
              from: data.startStr,
              to: data.endStr,
              note: '',
              listingId: +data.resource.id,
              isMultiUnit: data.resource.extendedProps.isMultiUnit,
              minNights: null,
              rates: data.resource.extendedProps.rates,
              status: 'available',
              onChange: this.updateRates,
              onReservationCreate: this.onReservationCreate,
            },
          })
        },
        dateClick: ({ dateStr, resource }) => {
          if (
            !resource.extendedProps.rates ||
            this.isInvestor ||
            this.isCleaner ||
            this.isCleaningManager
          ) {
            return
          }
          const availableDates = new Set()
          this.formattedEvents
            .filter(e => e.listingId === +resource.id)
            .forEach(e => {
              availableDates.add(e.start)
            })
          const to = this.$moment(dateStr)
            .clone()
            .add(1, 'days')
            .format('YYYY-MM-DD')
          this.$store.commit('showModal', {
            name: 'CalendarBlockModal',
            props: {
              from: dateStr,
              to: to,
              note: '',
              listingId: +resource.id,
              minNights: resource.extendedProps.rates[dateStr].minNights,
              isMultiUnit: resource.extendedProps.isMultiUnit,
              rates: resource.extendedProps.rates,
              status: 'available',
              onChange: this.updateRates,
              onReservationCreate: this.onReservationCreate,
            },
            isPersistent: false,
          })
        },
        views: {
          resourceTimelineMonth: {
            type: 'resourceTimelineMonth',
            duration: { weeks: 4 },
          },
        },
        slotLaneClassNames: () => {
          return 'p-relative'
        },
        resourceLabelClassNames({ resource }) {
          const classNames = []
          if (resource.extendedProps.isMultiUnit) {
            classNames.push('multi-unit')
          }
          if (resource.extendedProps.isHotel) {
            classNames.push('hotel')
          }
          if (
            !resource.extendedProps.isHotel &&
            !resource.extendedProps.isMultiUnit
          ) {
            classNames.push('yuval')
          }
          return classNames
        },
        resourceLaneClassNames({ resource }) {
          const classNames = []
          if (resource.extendedProps.isMultiUnit) {
            classNames.push('multi-unit-lane')
          }
          return classNames
        },
        eventClick: this.onEventClick,
        slotLabelFormat: [{ weekday: 'short' }],
        headerToolbar: false,
        resourceAreaWidth: this.isMobile ? 120 : 175,
        scrollTimeReset: false,
      }
    },
  },
  methods: {
    ...mapActions('reservation', ['getReservation']),
    ...mapActions({
      getMultiCalendarChat: 'getMultiCalendarChat',
    }),
    changeRegion() {
      this.fetchResources({
        region_id: this.region,
        resort_id: this.resort,
        listed: this.listedOnly,
      })
    },
    onReservationCreate(res_id) {
      this.getData()
      window.open(`/dashboard/reservation/${res_id}`, '_blank')
    },
    isPastDay(date) {
      return this.$moment(date).utc().isBefore(this.$moment())
    },
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice((page_number - 1) * page_size, page_number * page_size)
    },
    closeDrawer() {
      this.internalReservation = null
      this.resource = null
      this.event.setProp('classNames', this.eventClass)
      this.event.setProp('textColor', 'black')
      this.event = null
    },
    calcReservationPrice(res) {
      return res.price !== 0
        ? res.price
        : res.fare_accommodation + res.cleaning_fee + res.total_taxes
    },
    sendInfo() {
      this.$store.commit(
        'updatePushedMessage',
        this.internalReservation.generated_code_msg
      )
    },
    markAsPaid(reservationID) {
      const userConfirm = confirm('Are you sure?')
      if (userConfirm) {
        this.$store.dispatch('markAsPaid', reservationID)
      }
    },

    calc(hotelId, item) {
      const units = this.totalListings.filter(
        listing => listing.hotel_id === +hotelId
      )
      return units.reduce((res, unit) => {
        if (
          unit.days_rates[item] &&
          unit.days_rates[item].status === 'available'
        ) {
          res += 1
        }
        return res
      }, 0)
    },
    calcUnitsNum(hotelId) {
      return this.totalListings.filter(listing => listing.hotel_id === +hotelId)
        .length
    },
    updateRates(listings) {
      listings.forEach(listing => {
        const index = this.listings.findIndex(l => l.id === listing.id)
        if (index !== -1) {
          this.listings[index].days_rates = listing.days_rates
        }
      })
    },
    onEventClick({ event }) {
      if (event.extendedProps.isBlocked) {
        if (this.isInvestor || this.isCleaner || this.isCleaningManager) {
          return
        }
        const listingId = event.extendedProps.listingId
        const availableDates = new Set()
        this.formattedEvents
          .filter(e => e.listingId === listingId)
          .forEach(e => {
            availableDates.add(e.start)
          })
        const resource = this.calApi.getResourceById(listingId)
        this.$store.commit('showModal', {
          name: 'CalendarBlockModal',
          props: {
            from: event.startStr,
            to: event.endStr || event.startStr,
            note: event.title,
            listingId: event.extendedProps.listingId,
            minNights: event.extendedProps.minNights,
            isMultiUnit: resource.extendedProps.isMultiUnit,
            rates: resource.extendedProps.rates,
            status: event.extendedProps.isBlocked ? 'unavailable' : 'available',
            onChange: this.updateRates,
          },
          isPersistent: false,
        })
      } else {
        const reservation = this.reservations.find(r => r.id === +event.id)
        const resource = event.getResources()[0]
        if (this.isInvestor || this.isCleaner || this.isCleaningManager) {
          this.$store.commit('showModal', {
            name: 'ReservationCardModal',
            props: { reservation, timezone: resource.extendedProps.timezone },
            isPersistent: false,
          })
        } else {
          this.resource = resource
          this.event = event
          this.event.setProp(
            'classNames',
            this.eventClass.concat(this.activeClass)
          )
          this.event.setProp('textColor', 'white')
          this.$router.replace({
            query: { reservation: reservation.reservation_guesty_id },
          })
        }
      }
    },
    filterByTerm(arr) {
      if (this.searchTerm) {
        return arr.filter(
          listing =>
            listing.nickname
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) !== -1
        )
      }
      return arr
    },
    async getData(page = 1, allListings = false) {
      this.page = page
      const pageSize = this.isInvestor ? 100 : 20
      const newListings = this.paginate(
        this.filterByTerm(this.totalListings),
        pageSize,
        page
      )

      const mergedListings = uniqBy('id')([...this.listings, ...newListings])

      if (page === 1) {
        this.listings = newListings
      } else {
        this.listings = mergedListings
      }
      if (newListings.length === 0) {
        this.fetched = true
        return
      }
      this.fetching = true
      const params = {
        from: this.from,
        to: this.to,
        listings: (allListings ? mergedListings : newListings).map(l => l.id),
      }
      const query = {}
      if (params.from) query.from = params.from
      if (params.to) query.to = params.to
      if (this.searchTerm) query.searchTerm = this.searchTerm
      if (!isEqual(this.$route.query, query)) {
        this.$router.push({ query: { ...this.$route.query, ...query } })
      }
      const {
        data: { reservations },
      } = await axios.get('/api/multi-calendar-data', {
        params,
      })
      if (page === 1) {
        this.reservations = reservations
      } else {
        this.reservations = this.reservations.concat(reservations)
      }
      this.fetched = true
      this.fetching = false
    },
    onDateNavigation(change) {
      if (change > 0) {
        this.calApi.next()
      } else {
        this.calApi.prev()
      }
      this.assignDates()
      this.getData(this.page, true)
    },
    goToday() {
      this.calApi.today()
      var scrollTime = this.$moment().format('HH:mm:ss')
      this.calApi.scrollToTime(scrollTime)
      this.assignDates()
      this.page = 1
      this.getData(1)
    },
    goToDate(date) {
      this.calApi.gotoDate(date)
      const scrollTime = this.$moment(date).format('HH:mm:ss')
      this.calApi.scrollToTime(scrollTime)
      this.assignDates()
      this.page = 1
      this.getData(1)
    },
    assignDates() {
      this.from = this.parseDate(this.calApi.view.currentStart, 'YYYY-MM-DD', {
        local: true,
      })
      this.to = this.parseDate(this.calApi.view.currentEnd, 'YYYY-MM-DD', {
        local: true,
      })
    },
    onScroll() {
      // this.calScroll.onscroll = event => {
      //   const { scrollTop, scrollHeight, clientHeight } = event.target
      //   if (scrollTop && scrollHeight - scrollTop <= clientHeight) {
      //     if (this.listings.length < this.totalListings.length) {
      //       this.getData(++this.page)
      //     }
      //   }
      // }
    },
    loadMore() {
      this.getData(++this.page)
    },
    async openConversationInfo(id) {
      this.drawer = true
      let currentReservation = this.reservations.find(
        r => r.reservation_guesty_id === id
      )
      if (currentReservation && currentReservation.conversation) {
        this.internalReservation = null
        this.resource = null
        await this.getMultiCalendarChat({
          id: currentReservation.conversation.guesty_id,
          reservationId: currentReservation.id,
        })
      } else if (currentReservation) {
        this.internalReservation = currentReservation
      } else {
        currentReservation = await this.getReservation({ id })
        if (!currentReservation) {
          return console.log('invalid reservation id')
        }
        if (currentReservation.status !== 'confirmed') {
          return console.log('Reservation is not confirmed')
        }
        const date = new Date(currentReservation.check_in)
        this.calApi.gotoDate(new Date(date))
        this.assignDates()
        this.searchTerm = currentReservation.listing.nickname
        await this.getData()
        const foundReservation = this.reservations.find(
          r => r.reservation_guesty_id === id
        )
        if (!foundReservation) {
          this.reservations.push(currentReservation)
        }
        this.getMultiCalendarChat({
          id: currentReservation.conversation.guesty_id,
          reservationId: currentReservation.id,
        })
      }
      this.event = this.calApi.getEventById(currentReservation.id)
      this.event.setProp('classNames', this.eventClass.concat(this.activeClass))
      this.event.setProp('textColor', 'white')
    },
    onKeyUp() {
      this.debouncer.call(this)
    },
    sortListings(listings) {
      const sortI = l =>
        l.hotel_id && l.container_multi_id ? 3 : l.hotel_id ? 2 : 1
      return listings.sort((a, b) => {
        const compare = sortI(a) - sortI(b)
        if (compare === 0) {
          return a.nickname
            .toLowerCase()
            .localeCompare(b.nickname.toLowerCase())
        } else {
          return compare
        }
      })
    },
    async fetchResources(params = {}, quiet = false) {
      if (!quiet) this.fetching = true
      const query = {}
      if ('listed' in params) query.listedOnly = params.listed
      if (params.region_id) query.region = params.region_id
      if (!isEqual(this.$route.query, query)) {
        this.$router.push({ ...this.$route.query, query })
      }
      const {
        data: { listings, multi_unit_listings },
      } = await axios.get('/api/listing-calendar/resources', { params })
      this.totalListings = listings
      this.multiUnitListings = multi_unit_listings

      this.fetching = false
      const multiIds = uniq(listings.map(l => l.container_multi_id))
      this.listingsSet = new Set(listings.map(l => l.id).concat(multiIds))
      await this.getData()
    },
    loadScroller() {
      const el = document.getElementById('multi-cal')
      el.addEventListener('scroll', () => {
        const bottom = el.scrollHeight - el.scrollTop
        el.clientHeight > bottom * 0.9 && this.loadMore()
      })
    },
    async updateCheckInOutTimes({
      id,
      arrival,
      departure,
      luggage_drop,
      dvr_notes,
    }) {
      await this.$store.dispatch('reservation/alterReservation', {
        id: id,
        planned_arrival: arrival,
        planned_departure: departure,
        luggage_drop,
        dvr_notes,
      })
    },
    getMultiAvailable(resource, dataRangeItem) {
      return resource.getChildren().reduce((res, listing) => {
        const item = listing.extendedProps.rates[dataRangeItem]
        if (item && item.status === 'available') res += 1
        return res
      }, 0)
    },
    getHotelAvailable(resource, dataRangeItem) {
      const hasMulti = resource.getChildren()[0].extendedProps.isMultiUnit
      if (!hasMulti) return this.getMultiAvailable(resource, dataRangeItem)

      const availables = resource.getChildren().map(multiResource => {
        return this.getMultiAvailable(multiResource, dataRangeItem)
      })
      return sum(availables)
    },
    getHotelAll(resource) {
      const hasMulti = resource.getChildren()[0].extendedProps.isMultiUnit
      if (!hasMulti) return resource.getChildren().length

      const multiLengths = resource
        .getChildren()
        .map(multiResource => multiResource.getChildren().length)
      return sum(multiLengths)
    },
    listedOnlyChange() {
      this.fetchResources({
        region_id: this.region,
        listing_id: this.listingId,
        listed: this.listedOnly,
      })
    },
    formatCalendarEvent(event) {
      if (!event?.end) return event

      const endsAtCalendarStart =
        this.$moment(event.end).format('YYYY-MM-DD') ===
        this.$moment(this.from).format('YYYY-MM-DD')

      if (!endsAtCalendarStart) return event

      const endDate = this.$moment(event.end).set({
        hour: 10,
        minute: 0,
        second: 0,
      })

      return {
        ...event,
        classNames: [...(event.classNames || []), 'boundary-event'],
        end: this.parseDateTZ(endDate, 'YYYY-MM-DDTHH:mm:ss'),
      }
    },
    onReservationEdit() {
      this.getData(this.page, true)
      this.drawer = false
    },
  },
  watch: {
    async calendarUpdates() {
      await this.fetchResources({
        region_id: this.region,
        listing_id: this.listingId,
        start_date: this.from,
        end_date: this.to,
        listed: this.listedOnly,
      })
      this.debouncer.call(this)
      if (this.$route.query.reservation) {
        this.openConversationInfo(this.$route.query.reservation)
      }
    },
    currenReservationId(id) {
      if (id) {
        this.event = this.calApi.getEventById(id)
        this.event.setProp(
          'classNames',
          this.eventClass.concat(this.activeClass)
        )
        this.event.setProp('textColor', 'white')
      }
    },
    drawer(val) {
      if (!val) {
        this.$router.replace({
          query: null,
        })
        this.closeDrawer()
      }
    },
    '$route.query.reservation': {
      handler(id) {
        if (id) {
          this.openConversationInfo(id)
        }
      },
    },
  },
  async mounted() {
    this.debouncer = debounce(this.getData, 750)
    this.region = this.currentUser.region ? +this.currentUser.region : null
    this.calApi = this.$refs.calendar.getApi()
    const scrollClass = 'fc-scroller-liquid-absolute'
    this.calScroll = document.getElementsByClassName(scrollClass)[1]
    this.assignDates()

    const params = this.$route.query

    if (params.region) this.region = Number(params.region)
    if (params.listedOnly === 'false') this.listedOnly = false
    if (params.from) {
      this.from = params.from
      this.goToDate(params.from)
    }
    if (params.to) this.to = params.to
    if (params.searchTerm) this.searchTerm = params.searchTerm

    await this.fetchResources({
      region_id: this.region,
      listing_id: this.listingId,
      start_date: this.from,
      end_date: this.to,
      listed: this.listedOnly,
    })
    this.loadScroller()
    this.fetchResources(
      {
        region_id: this.region,
        listing_id: this.listingId,
        listed: this.listedOnly,
      },
      true
    )
    if (this.$route.query.reservation) {
      const reservation = this.reservations.find(
        r => r.reservation_guesty_id === this.$route.query.reservation
      )
      this.internalReservation = reservation
      this.resource = this.calApi.getResourceById(reservation.listing_id)
      this.openConversationInfo(this.$route.query.reservation)
      this.drawer = true
    }
  },
}
</script>
<style scoped>
.multi-container {
  height: calc(100vh - 160px);
}
#multi-cal >>> .event-reservation {
  position: relative;
  transform: skew(-22deg) translateX(0px);
  margin: 7px -13px 0 22px;
}

#multi-cal >>> .boundary-event {
  position: absolute;
  visibility: visible;
  margin-left: -10px;
  width: 25px;
}

#multi-cal >>> .reservation-bar {
  background-color: #7ebac0a8 !important;
}

#multi-cal >>> .reserved {
  background-color: #e3d2f5 !important;
}

#multi-cal >>> .confirmed {
  background-color: #87cf92a8 !important;
}

#multi-cal >>> .canceled {
  background-color: #cf8787a8 !important;
}

#multi-cal >>> .child-res {
  background-color: rgba(207, 176, 135, 0.66) !important;
}

#multi-cal >>> .day-cell {
  transform: translateY(-50%);
  margin-top: 50%;
  border: none !important;
}

#multi-cal >>> .no-border {
  margin-top: 5%;
  border: none !important;
}

#multi-cal >>> .event-reservation-content {
  height: 30px;
  transform: skew(22deg);
}

#multi-cal >>> .fc-timeline-event:not(.fc-event-end):after,
#multi-cal >>> .fc-timeline-event:not(.fc-event-start):before {
  display: none !important;
}

#multi-cal >>> .fc-datagrid-cell-frame {
  display: table-cell;
  vertical-align: middle;
}
.cell-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.source-icon {
  padding: 3px 6px;
}

#multi-cal >>> .block-bg {
  border: none;
  background-color: rgba(183, 176, 176, 0.7);
  background-image: repeating-linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px,
      transparent 30px
    ),
    repeating-linear-gradient(
      60deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px,
      transparent 30px
    ),
    linear-gradient(60deg, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1)),
    linear-gradient(
      120deg,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1)
    );
}

#multi-cal >>> .availability-block {
  border: none;
  /*background-color: #eee; !* Light grey background *!*/
  background-image: repeating-linear-gradient(
    115deg,
    #ddd,
    #ddd 10px,
    #ffffff 10px,
    #ffffff 20px
  );
  color: black !important;
}
#multi-cal >>> .availability-block.fc-h-event .fc-event-main {
  color: black !important;
}

#multi-cal >>> .multi-unit {
  background-color: var(--v-light300-base) !important;
  height: 45px;
}
#multi-cal >>> .multi-unit-lane {
  height: 45px;
}

#multi-cal >>> .hotel {
  background-color: var(--v-light400-base) !important;
}

#multi-cal >>> .today-lane {
  opacity: 0.7;
}

#multi-cal >>> .min-nights {
  font-size: 10px;
  display: flex;
}
#multi-cal >>> .fc-resource .fc-datagrid-expander-placeholder {
  display: none;
}
#multi-cal >>> .multi-unit .fc-datagrid-cell-cushion > .fc-icon {
  display: none !important;
}
#multi-cal >>> .yuval .fc-icon {
  display: none !important;
}
:deep() .fc-scrollgrid-section-sticky > * {
  z-index: 100 !important;
}
:deep() .fc-view-harness {
  z-index: 100;
}
#multi-cal {
  overflow: auto;
  height: calc(100vh - 160px);
}

#multi-cal >>> .fc-timeline-header-row > th {
  border: none;
}
#multi-cal >>> .fc-timeline-header-row > .fc-day-today .label-container {
  background: var(--v-black950-base) !important;
  border-radius: 6px;
  width: 50px;
  color: white;
}
#multi-cal >>> .fc-timeline-header-row > .fc-day-today .text-caption {
  color: white !important;
}

.select-col {
  z-index: 200;
}

.v-tooltip__content {
  z-index: 10000 !important;
}
.manual-override {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
</style>
