import axios from 'axios'

export default {
  data() {
    return {
      tags: [],
    }
  },
  methods: {
    async fetchTags() {
      try {
        const { data } = await axios.get('/api/listings/tags')
        this.tags = Array.from(new Set(data.filter(tag => tag && tag.trim())))
      } catch (error) {
        console.error('Error fetching tags:', error)
      }
    },
  },
}
