<template>
  <v-col class="requests">
    <v-dialog v-model="dialog" persistent max-width="650px">
      <v-form ref="form" lazy-validation @submit="submitForm">
        <v-card>
          <v-card-title
            class="center-text cyanish bolder headline-font white--text"
          >
            Create new info definition
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row class="wrap">
                <v-col cols="12">
                  <v-combobox
                    v-model="item.topic"
                    label="Topic"
                    :items="topics"
                    :rules="[required]"
                    class="small-select"
                    dense
                    outlined
                  />
                </v-col>
                <v-col v-if="item.topic" cols="12">
                  <v-text-field
                    v-model="item.sub_topic"
                    class="small-select"
                    :rules="[required]"
                    label="Sub topic"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col v-if="item.sub_topic && item.topic" cols="12">
                  <v-text-field
                    v-model="item.hint"
                    class="small-text-input"
                    label="Additional desc"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="item.tag"
                    class="small-text-input"
                    :items="tags"
                    label="Tag"
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="closeFunc"
              >Close</v-btn
            >
            <v-btn
              color="blue darken-1"
              :disabled="loading"
              text
              @click.native="submitForm"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-col>
</template>

<script>
import axios from 'axios'

import formRules from '../mixins/form-rules-mixin'
import TagsMixin from '../mixins/tags-mixin'

import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [CommonFunctions, formRules, PermissionsMixin, TagsMixin],
  props: ['closeFunc'],
  data() {
    return {
      dialog: true,
      item: {},
      search: null,
      createNewSubTopic: false,
    }
  },
  computed: {
    topics() {
      return [
        'Admin',
        'Attractions',
        'Backyard',
        'Bathroom',
        'Bedroom',
        'Design',
        'Front yard',
        'Garage',
        'General info',
        'Kitchen',
        'Laundry',
        'Licences and agreements',
        'Living/Dining',
        'Marketing materials',
        'Pool',
        'Setup',
        'Smart home devices',
        'Utilities',
      ]
    },
  },
  async mounted() {
    this.fetchTags()
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        this.$store.dispatch('createListingInfoDef', this.item)
        this.closeFunc()
      }
    },
  },
}
</script>
